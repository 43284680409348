
import {Link} from "@inertiajs/react";
import Lang from "lang.js";

var lang = new Lang();
var applicationMessages = await fetch("/lang.dist.js")
    .then((response) => response.json())
    .then((json) => {
        return json;
    });
lang.setMessages(applicationMessages);
export default function LoginFooter() {
    const currentYear = new Date().getFullYear();
    return (
        <div className="login_page_grid_footer">
            <div className="login_page_grid_footer_left">&copy; {currentYear} {lang.get('rido.general.website_url')}</div>
            <ul className="login_page_grid_footer_right">
                <li><Link href="/">{lang.get('rido.general.privacy_policy')}</Link></li>
                <li><Link href="/">{lang.get('rido.general.terms_conditions')}</Link></li>
            </ul>
        </div>
    );
}
