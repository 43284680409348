import ApplicationLogo from '@/Components/HeaderComponents/ApplicationLogo';
import { Link } from '@inertiajs/react';
import Lang from "lang.js";

var lang = new Lang();
var applicationMessages = await fetch("/lang.dist.js")
    .then((response) => response.json())
    .then((json) => {
        return json;
    });
lang.setMessages(applicationMessages);
export default function Guest({ children }) {
    return (
        <>
            {children}
        </>
    );
}
