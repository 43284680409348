
import {Link} from "@inertiajs/react";
import Lang from "lang.js";

var lang = new Lang();
var applicationMessages = await fetch("/lang.dist.js")
    .then((response) => response.json())
    .then((json) => {
        return json;
    });
lang.setMessages(applicationMessages);
export default function LoginHeader() {
    return (
        <div className="login_page_grid_header">
            <Link href="/login" className="login_page_grid_header_logo"><img src="/rido-logo.svg" alt=""/></Link>
        </div>
    );
}
